@tailwind base;

@tailwind components;

@tailwind utilities;

@import url("https://fonts.googleapis.com/css?family=Poppins:400,700|Roboto:200,400&display=swap");
@import url("fontawesome/css/all.min.css");
@import url("ti/themify-icons.css");

body {
  font-family: Roboto, sans-serif;
}

.navigation {
  overflow: auto;
}
